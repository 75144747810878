import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type DocumentNode, type QueryResult } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, MenuItem, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { PopperMenu } from 'components/PopperMenu';
import { useReportExport } from 'hooks/useReportExport';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { showToast } from 'utils';
import { normalizedKeys } from './translationMap';
import { getColumnMappers, type TColumnMapperTransformFn } from './utils';

interface IQuery {
  type: 'csv' | 'xlsx';
  query: DocumentNode;
  key: string;
  label: string;
}

interface INormalization {
  normalizeCurrency?: string;
  currencyDate?: string;
}

interface ITableExportProps<T> extends INormalization {
  authorized?: boolean;
  blockConditions?: {
    if: boolean;
    message: string;
  }[];
  columnMapperFn?: TColumnMapperTransformFn;
  excludedKeys?: string[];
  isExportBlocked?: boolean;
  filter: T;
  queries: IQuery[];
}

export function TableExport<T>({
  queries,
  authorized = true,
  excludedKeys,
  filter,
  columnMapperFn,
  blockConditions,
  currencyDate,
  normalizeCurrency,
}: ITableExportProps<T>) {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { generatingReport, generate } = useReportExport();

  const {
    userPreferences: { timezone },
  } = useUserPreferences();

  const handleClick = useCallback((query: DocumentNode, result: (result: QueryResult) => string) => {
    setOpen(false);

    const trueBlockCondition = blockConditions?.find((condition) => condition.if);

    if (trueBlockCondition) {
      showToast({ severity: 'warning', title: trueBlockCondition.message });
      return null;
    }

    const sortModel = apiRef.current.getSortModel();
    const columns = apiRef.current?.getVisibleColumns();
    const columnsMappers = columnMapperFn ? columnMapperFn(columns) : getColumnMappers(columns);
    const filteredColumnsMappers = columnsMappers.filter((mapper) => !excludedKeys?.includes(mapper.field));
    const orderBy = normalizeCurrency
      ? normalizedKeys[sortModel[0]?.field as keyof typeof normalizedKeys]
      : sortModel[0]?.field;

    const currencyDateUpdated = dayjs(currencyDate).tz(timezone).hour(12).minute(0).second(0).utc().format();

    generate(
      query,
      {
        columnsMappers: filteredColumnsMappers,
        orderBy,
        orderDirection: sortModel[0]?.sort,
        timezone,
        ...filter,
        ...(normalizeCurrency && { baseCurrency: normalizeCurrency }),
        ...(currencyDate && {
          currencyDate: currencyDateUpdated,
        }),
      },
      result,
    );
  }, []);

  if (!authorized) {
    return null;
  }

  return (
    <>
      <Tooltip title={generatingReport ? t('reportExport.onlyOneAtTime') : ''}>
        <Box>
          <Button
            ref={buttonRef}
            disabled={generatingReport}
            variant="text"
            size="small"
            onClick={() => setOpen((s) => (generatingReport ? false : !s))}
          >
            <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
            {t('base.export')}
          </Button>
        </Box>
      </Tooltip>
      <PopperMenu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        {queries.map(({ key, label, query }) => (
          <MenuItem key={key} onClick={() => handleClick(query, (result) => result.data[key].url)}>
            {label}
          </MenuItem>
        ))}
      </PopperMenu>
    </>
  );
}
