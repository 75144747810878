import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  HeaderText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from 'components';
import {
  EUserRole,
  EUsersBulkAction,
  EUserStatus,
  useLoading,
  useUser,
  useUserManagement,
  type IUserInput,
} from 'hooks';
import { ERoutes } from 'routing/routes';
import { isSpecificError, showToast } from 'utils';
import NotFound from 'views/NotFound';
import { getScopes, type TScopeError } from './accessesFeature/getScopes';
import TeamClientsGroup from './TeamClientsGroup';
import TeamClusterGroup from './TeamClustersGroup';
import TeamMemberUserMoreButton from './TeamMemberUserMoreButton';
import TeamMemberUserPasswordPopup from './TeamMemberUserPasswordPopup';
import TeamOperatorsGroup from './TeamOperatorsGroup';
import { useMemberAtoms } from './useMemberAtoms';

export const TeamMemberUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const newUser = id === 'add';
  const currentUser = useUser();
  const { rawData, updateUser, addUser, error, handleUsersBulkAction } = useUserManagement(id, newUser);
  const { loading } = useLoading();
  const [newUserData, setNewUserData] = useState<{ password: string; email: string } | null>(null);
  const navigate = useNavigate();

  const scopes = useMemo(() => (rawData?.user?.scopes ? rawData?.user?.scopes : []), [rawData]);

  const { query, clusterAtomGroup, clientAtomGroup, operatorAtomGroup } = useMemberAtoms({
    scopes,
  });

  const [scopeValidation, setScopeValidation] = useState<TScopeError[]>([]);

  const handleAccept = useCallback(
    (data: Record<string, string>) => {
      const scopes = getScopes(clusterAtomGroup, clientAtomGroup, operatorAtomGroup);

      if (!scopes.validationPassed) {
        setScopeValidation(scopes.errors);
        showToast({
          title: t('base.selectionMissing'),
          severity: 'error',
        });
      } else {
        setScopeValidation([]);

        const userInput: IUserInput = {
          role: data.role as EUserRole,
          scopes: scopes.value,
          ...(data.status !== 'PENDING' && { status: data.status as EUserStatus }),
        };

        if (newUser) {
          userInput.email = data.email;
          addUser(userInput).then((user) => setNewUserData(user ?? null));
        } else {
          updateUser(userInput, id as string);
        }
      }
    },
    [rawData],
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('login.notValidEmail')).required('Required'),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: { email: '', role: EUserRole.ACCOUNT_MANAGER, status: EUserStatus.PENDING },
    onSubmit: handleAccept,
  });

  useEffect(() => {
    if (rawData) {
      formik.setValues({ email: rawData.user.email, role: rawData.user.role, status: rawData.user.status });
    }
  }, [rawData]);

  if (
    isSpecificError(error, 'Cannot return null for non-nullable field Query.user.') ||
    rawData?.user.id === currentUser.id
  ) {
    return <NotFound overrideTitle={t('team.editUser')} overrideSubtitle={t('team.userNotFound', { id })} />;
  }

  const handleDelete = useCallback(async () => {
    if (rawData.user?.id) {
      const passed = await handleUsersBulkAction(EUsersBulkAction.DELETE, [rawData.user?.id]);
      if (passed) {
        navigate(ERoutes.ManagementTeamMembers);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        left: 0,
        pb: 2,
      }}
    >
      <TeamMemberUserPasswordPopup
        userData={newUserData}
        onClose={() => setNewUserData(null)}
        title={t('team.userCreated')}
      />
      <HeaderText>{t(newUser ? 'team.addUser' : 'team.editUser')}</HeaderText>

      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ position: 'relative', p: 2, display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
            <Typography variant="h6" sx={{ flexGrow: 1, ml: 0.5 }}>
              {t('team.userDetails')}
            </Typography>
            <Button disabled={loading} type="submit">
              {t('base.accept')}
            </Button>
            {!newUser && <TeamMemberUserMoreButton disabled={loading} user={rawData?.user} deleteUser={handleDelete} />}
          </Box>
          <TextField
            size="small"
            name="email"
            label={t('login.email')}
            required
            disabled={!newUser || loading}
            sx={{ flexGrow: 1 }}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.submitCount > 0 && formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.submitCount > 0 && formik.touched.email && formik.errors.email}
          />
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel size="small">{t('team.role')}</InputLabel>
            <Select
              size="small"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              disabled={loading}
              label={t('team.role')}
            >
              <MenuItem value={EUserRole.AREA_ADMIN}>{t('team.areaAdmin')}</MenuItem>
              <MenuItem value={EUserRole.ACCOUNT_MANAGER}>{t('team.accountManager')}</MenuItem>
              <MenuItem value={EUserRole.SUPPORT}>{t('team.support')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel size="small">{t('team.status')}</InputLabel>
            <Select
              disabled={formik.values.status === EUserStatus.PENDING || loading}
              size="small"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              label={t('team.status')}
            >
              <MenuItem disabled value={EUserStatus.PENDING}>
                {t('team.pending')}
              </MenuItem>
              <MenuItem value={EUserStatus.ACTIVE}>{t('team.active')}</MenuItem>
              <MenuItem value={EUserStatus.INACTIVE}>{t('team.inactive')}</MenuItem>
            </Select>
          </FormControl>
        </Paper>

        <Paper sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TeamClusterGroup loading={query.loading} atoms={clusterAtomGroup} />
            <TeamClientsGroup
              loading={query.loading}
              atoms={clientAtomGroup}
              failedValidationIds={scopeValidation
                .filter((validation) => validation.type === 'clusterChildNotSelected')
                .map((validation) => validation.clientIds)
                .flat()}
            />
            <TeamOperatorsGroup
              loading={query.loading}
              atoms={operatorAtomGroup}
              failedValidationIds={scopeValidation
                .filter((validation) => validation.type === 'clientChildNotSelected')
                .map((validation) => validation.operatorIds)
                .flat()}
            />
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default TeamMemberUser;
