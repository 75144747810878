export const exportTranslations = {
  'values.debitAmount': 'accounting.totalBet',
  'values.creditAmount': 'accounting.totalWin',
  'normalizedValues.debitAmount': 'accounting.exportNormalizedTotalBet',
  'normalizedValues.creditAmount': 'accounting.exportNormalizedTotalWin',
};

export const normalizedKeys = {
  creditAmount: 'nCreditAmount',
  debitAmount: 'nDebitAmount',
};
