import { gql } from '@apollo/client';

export const GET_REPORTS_CSV = gql`
  query ReportTransactionsCsv(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $normalized: Boolean!
    $groupBy: String
    $currencyDate: DateTime
    $baseCurrency: String
    $columnsMappers: [ReportColumnMapperInput!]!
    $timezone: String
  ) {
    reportTransactionsCsv(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalized: $normalized
      groupBy: $groupBy
      currencyDate: $currencyDate
      baseCurrency: $baseCurrency
      columnsMappers: $columnsMappers
      timezone: $timezone
    ) {
      url
    }
  }
`;

export const GET_REPORTS_XLSX = gql`
  query ReportTransactionsXlsx(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $normalized: Boolean!
    $groupBy: String
    $currencyDate: DateTime
    $baseCurrency: String
    $columnsMappers: [ReportColumnMapperInput!]!
    $timezone: String
  ) {
    reportTransactionsXlsx(
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalized: $normalized
      groupBy: $groupBy
      currencyDate: $currencyDate
      baseCurrency: $baseCurrency
      columnsMappers: $columnsMappers
      timezone: $timezone
    ) {
      url
    }
  }
`;
