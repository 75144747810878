import { useTranslation } from 'react-i18next';
import { type TFilter } from 'components/ReportExport/types';
import { TableExport } from 'components/TableExport';
import { exportTranslations } from 'components/TableExport/translationMap';
import { buildNormalizedMappers } from 'components/TableExport/utils';
import { GET_BETS_CSV, GET_BETS_XLSX } from 'views/PlayerBrowser/exportQueries';

export const BetExport = ({ filter }: { filter: TFilter }) => {
  const { t } = useTranslation();

  return (
    <TableExport
      filter={{ filter }}
      excludedKeys={['createdAt']}
      blockConditions={[{ if: !filter, message: t('accounting.applyFiltersBeforeExport') }]}
      columnMapperFn={buildNormalizedMappers(
        {
          normalizeCurrency: filter && (filter.baseCurrency as string),
          affectedFields: ['debitAmount', 'creditAmount'],
          prefixes: ['normalizedValues', 'values'],
          translations: exportTranslations,
        },
        t,
      )}
      queries={[
        {
          type: 'csv',
          query: GET_BETS_CSV,
          key: 'betsCsv',
          label: 'CSV',
        },
        {
          type: 'xlsx',
          query: GET_BETS_XLSX,
          key: 'betsXlsx',
          label: 'XLSX',
        },
      ]}
      normalizeCurrency={filter?.baseCurrency as string}
      currencyDate={filter?.currencyDate as string}
    />
  );
};
