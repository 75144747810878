import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Perms } from 'auth';
import dayjs from 'dayjs';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter, type IButtonInputsFormInputType } from 'components';
import { EFilterTesters, useButtonInputsFormQueryParams, usePerms, type IDefaultFiltersData } from 'hooks';
import { mapButtonInputsToCurrency } from 'utils';

const generateInputsData = (perms: Perms) => {
  const maxDate = dayjs().subtract(1, 'day');
  const minDate = dayjs().subtract(1, 'year');

  const data = [
    perms.clusters && { type: 'multiselect', key: 'clusters', translateKey: 'playerBrowser.clusters' },
    perms.clients && { type: 'multiselect', key: 'clients', translateKey: 'playerBrowser.clients' },
    perms.operators && { type: 'multiselect', key: 'operators', translateKey: 'playerBrowser.operators' },
    { type: 'multiselect', key: 'games', translateKey: 'playerBrowser.games' },
    { type: 'uuid', key: 'playerId', translateKey: 'playerBrowser.playerId' },
    { type: 'string', key: 'playerExternalId', translateKey: 'playerBrowser.playerExternalId' },
    { type: 'uuid', key: 'playerInternalId', translateKey: 'playerBrowser.playerInternalId' },
    { type: 'uuid', key: 'betId', translateKey: 'playerBrowser.betId' },
    { type: 'number', key: 'bet', translateKey: 'playerBrowser.betValue' },
    { type: 'number', key: 'win', translateKey: 'playerBrowser.winValue' },
    { type: 'date', key: 'betTime', translateKey: 'playerBrowser.betTime' },
    { type: 'multiselect', key: 'currencies', translateKey: 'playerBrowser.currencies' },
    { type: 'multiselect', key: 'countries', translateKey: 'playerBrowser.countries' },
    { type: 'multiselect', key: 'entityType', translateKey: 'playerBrowser.betType' },
    { type: 'select', key: 'tester', translateKey: 'accounting.Players' },
    {
      type: 'currencyNormalization',
      key: 'currencyNormalization',
      translateKey: 'playerBrowser.currencyNormalization',
      config: {
        dateTranslateKey: 'accounting.CurrencyDate',
        minDate,
        maxDate,
        selected: 'NONE',
      },
    },
  ].filter(Boolean) as { type: IButtonInputsFormInputType; key: string; translateKey: string }[];

  return data;
};

const initialShowingInputs = [
  'clusters',
  'clients',
  'operators',
  'games',
  'playerExternalId',
  'betId',
  'bet',
  'win',
  'betTime',
  'currencyNormalization',
];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  selectOptions: IDefaultFiltersData | null;
  loading: boolean;
}

export const PlayerBrowserFilters = ({ onAccept, loading, selectOptions }: IProps) => {
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search);

  const [showingInputs, setShowingInputs] = useState(() => {
    return [...initialShowingInputs, ...Object.keys(initialState).filter((key) => !initialShowingInputs.includes(key))];
  });

  const { t } = useTranslation();
  const perms = usePerms();

  const inputsData = useMemo(() => generateInputsData(perms), [perms]);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = mapButtonInputsToCurrency(filters, ['bet', 'win']);

    setQueryParams(mappedFilters);
    onAccept(mappedFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <ButtonInputForm
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...selectOptions,
          entityType: [
            { name: t('playerBrowser.regularBet'), id: 'BET' },
            { name: t('playerBrowser.bonusBet'), id: 'BONUS' },
          ],
          tester: [
            { name: t('accounting.testersPlayersOnly'), id: EFilterTesters.TESTERS_ONLY },
            { name: t('accounting.realPlayersOnly'), id: EFilterTesters.REAL_ONLY },
            { name: t('accounting.allPlayers'), id: EFilterTesters.ALL },
          ],
        }}
        onAccept={handleAccept}
      />
    </Box>
  );
};

export default PlayerBrowserFilters;
