import type { TFunction } from 'i18next';

export type TPlayerBrowserDetailField =
  | 'entityId'
  | 'createdAt'
  | 'slotLabel'
  | 'debitAmount'
  | 'creditAmount'
  | 'currency'
  | 'entityType';

export const generateColumns = (t: TFunction) =>
  [
    { field: 'entityId', headerName: t('playerBrowser.betId') },
    { field: 'createdAt', headerName: t('playerBrowser.date') },
    { field: 'slotLabel', headerName: t('playerBrowser.gameName') },
    { field: 'debitAmount', headerName: t('playerBrowser.bet'), align: 'right', headerAlign: 'right' },
    { field: 'creditAmount', headerName: t('playerBrowser.win'), align: 'right', headerAlign: 'right' },
    { field: 'currency', headerName: t('playerBrowser.currency') },
    { field: 'entityType', headerName: t('playerBrowser.roundType') },
  ] as const;
