import { gql } from '@apollo/client';
import { t } from 'i18next';
import { parseCountryCode, parseCurrencyNumber, parseDateStringTz } from 'utils';
import { type IBetsSummary } from './PlayerBrowserDetailsTable';

interface IRawRecord {
  clusterLabel?: string;
  clientLabel?: string;
  currency: string;
  entityId: string;
  entityType: string;
  operatorLabel: string;
  playerExternalId: string;
  playerId: string;
  playerInternalId: string;
  slotLabel: string;
  tester: boolean;
  createdAt: string;
  country: string;
  historyViewerUrl: string;
  values: {
    creditAmount: number;
    debitAmount: number;
  };
  normalizedValues?: {
    creditAmount: number;
    debitAmount: number;
  };
}

export interface IPlayerBrowserDetailsRawData {
  bets: { items: IRawRecord[]; skip: number; take: number; totalCount: number; summary: IBetsSummary };
}

export const GET_PLAYER = gql`
  query GetPlayer(
    $where: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $currencyDate: DateTime
    $baseCurrency: String
  ) {
    bets(
      filter: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      currencyDate: $currencyDate
      baseCurrency: $baseCurrency
    ) {
      totalCount
      skip
      take
      summary {
        creditTotalSummary
        debitTotalSummary
      }
      items {
        slotLabel
        playerId
        playerInternalId
        playerExternalId
        operatorLabel
        clusterLabel
        clientLabel
        entityId
        entityType
        currency
        createdAt
        country
        historyViewerUrl
        values {
          debitAmount
          creditAmount
        }
        normalizedValues {
          debitAmount
          creditAmount
        }
      }
    }
  }
`;

export const parsePlayerBrowserDetailsData = (
  rawData: IPlayerBrowserDetailsRawData,
  lang: string,
  timezone: string,
  baseCurrency: string | null | unknown,
) => {
  return {
    totalCount: rawData.bets.totalCount,
    summary: rawData.bets.summary,
    data: rawData.bets.items.map((item) => {
      return {
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clientLabel,
        country: parseCountryCode(item.country, lang),
        historyViewerUrl: item.historyViewerUrl,
        //
        slotLabel: item.slotLabel,
        id: item.entityId,
        entityId: item.entityId,
        currency: item.currency,
        createdAt: parseDateStringTz(item.createdAt, timezone),

        entityType: item.entityType === 'BONUS' ? t('playerBrowser.bonusRound') : t('playerBrowser.betRound'),
        ...(typeof baseCurrency === 'string'
          ? {
              debitAmount: parseCurrencyNumber(item.normalizedValues?.debitAmount, baseCurrency),
              creditAmount: parseCurrencyNumber(item.normalizedValues?.creditAmount, baseCurrency),
            }
          : {
              debitAmount: parseCurrencyNumber(item.values?.debitAmount),
              creditAmount: parseCurrencyNumber(item.values?.creditAmount),
            }),
      };
    }),
  };
};
