import { useEffect, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridApiRef, type GridSortModel } from '@mui/x-data-grid';
import { DataGridBox, DataGridPagination, DataGridToolbar, DefaultDataGrid } from 'components';
import { type TFilter } from 'components/ReportExport/types';
import { useLoading } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { parseCurrencyNumber } from 'utils';
import { generateSummary } from 'utils/generateSummary';
import GridFooterSummary from 'views/AccountingReport/AccountingReportTable/GridFooterSummary';
import { BetExport } from './BetExport';
import { generateColumns, type TPlayerBrowserDetailField } from './playerBrowserDetailsGenerateColumn';

export interface IBetsSummary {
  creditTotalSummary: number;
  debitTotalSummary: number;
}

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: object[];
  totalCount: number;
  selectedBetId: string | number | null;
  setSelectedBetId: Dispatch<SetStateAction<string | number | null>>;
  filter: TFilter;
  summary: IBetsSummary | object;
}
export const PlayerBrowserDetailsTable = ({
  setSelectedBetId,
  selectedBetId,
  sort,
  setSort,
  totalCount,
  page,
  setPage,
  data,
  filter,
  summary,
}: IProps) => {
  const { t, i18n } = useTranslation();

  const apiRef = useGridApiRef();
  const { loading } = useLoading();
  const columns = useMemo(() => generateColumns(t), [i18n.language]);

  const normalizeCurrency = filter?.baseCurrency as string;

  const footerSummary = useMemo(
    () =>
      generateSummary<TPlayerBrowserDetailField, IBetsSummary>(
        summary as IBetsSummary,
        {
          grid: apiRef.current,
          parser: ({ creditTotalSummary, debitTotalSummary }) => {
            return {
              debitAmount: parseCurrencyNumber(debitTotalSummary, normalizeCurrency),
              creditAmount: parseCurrencyNumber(creditTotalSummary, normalizeCurrency),
            };
          },
        },
        t,
      ),
    [summary, i18n.language],
  );

  const { height } = useComputedGridHeight({ apiRef, additionalRowCount: normalizeCurrency ? 1 : 0 });

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const nextRowIndexDiff = e.key === 'ArrowDown' ? 1 : e.key === 'ArrowUp' ? -1 : 0;
      if (nextRowIndexDiff) {
        setSelectedBetId((currentId) => {
          if (currentId) {
            const selectedRowIndex = apiRef.current?.getRowIndexRelativeToVisibleRows(currentId);
            const newRowId = apiRef.current?.getRowIdFromRowIndex(selectedRowIndex + nextRowIndexDiff);
            if (newRowId) {
              return newRowId;
            }
          }
          return currentId;
        });
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        onRowSelectionModelChange={(e) => setSelectedBetId((currentId) => (currentId === e[0] ? null : e[0]))}
        rowSelectionModel={selectedBetId ? [selectedBetId] : []}
        disableRowSelectionOnClick={false}
        slots={{
          toolbar: () => (
            <DataGridToolbar localStorageKey="player-browser-details">
              <BetExport filter={filter} />
            </DataGridToolbar>
          ),
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,

          footer: normalizeCurrency
            ? () => (
                <GridFooterSummary page={page} setPage={setPage} totalDataCount={totalCount} summary={footerSummary} />
              )
            : undefined,
        }}
      />
    </DataGridBox>
  );
};

export default PlayerBrowserDetailsTable;
