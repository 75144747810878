import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language: string) => {
      return import(
        /* webpackChunkName: "i18n-[request]" */
        /* webpackMode: "lazy" */
        `./resources.${language.toLowerCase().slice(0, 2)}.json`
      );
    }),
  )
  .use(initReactI18next)
  .init({
    debug: import.meta.env.VITE_NODE_ENV === 'DEVELOPMENT',
    fallbackLng: 'en-US',
    preload: ['en-US'],
    load: 'languageOnly',
  });

export default i18n;
