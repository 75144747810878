import { type GridApiCommunity } from '@mui/x-data-grid/internals';
import { type TFunction } from 'i18next';

type TGenerateSummary<TField extends string, TSummary> = {
  grid: GridApiCommunity;
  parser: (mappings: TSummary) => Partial<Record<TField, string>>;
};

export function generateSummary<TField extends string, TSummary>(
  rawSummary: TSummary,
  { grid, parser }: TGenerateSummary<TField, TSummary>,
  t: TFunction,
) {
  if (rawSummary) {
    const firstColumnField = grid.getVisibleColumns?.()[0]?.field as TField;

    return {
      [firstColumnField]: t('accounting.summary'),
      ...parser(rawSummary),
    };
  }

  return null;
}
