import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoIcon from 'assets/images/LogoIcon';
import LogoTitle from 'assets/images/LogoTitle';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Fade,
  IconButton,
  LanguageSelector,
  ListItem,
  ListItemButton,
  ListItemText,
  ModeToggle,
  PopperMenu,
  ReportExport,
  TimezoneSelector,
  Toolbar,
  Tooltip,
} from 'components';
import { useAuth, useSidemenu, useUser, useUserPreferences } from 'hooks';
import { ERoutes } from 'routing/routes';
import { userRoleMap } from 'views/TeamMembers';

export const Header = () => {
  const user = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const {
    userPreferences: { timezone },
    setUserPreference,
  } = useUserPreferences();

  const { menuOpen } = useSidemenu();

  return (
    <AppBar
      position="fixed"
      sx={{ 'zIndex': (theme) => theme.zIndex.drawer + 2, '.MuiToolbar-root': { minHeight: 42 } }}
    >
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LogoIcon lightColor="#ffffff" darkColor="#ffffff" />

          <Fade in={menuOpen}>
            <Box sx={{ display: 'flex', position: 'relative', right: 10 }}>
              <LogoTitle lightColor="#ffffff" darkColor="#ffffff" />
            </Box>
          </Fade>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ width: 220 }}>
          <TimezoneSelector
            minimalistic
            value={timezone}
            onChange={(timezone) => setUserPreference('timezone', timezone)}
          />
        </Box>
        <ReportExport />
        <ModeToggle />
        <LanguageSelector variant="icon" />
        <Tooltip title={!menuOpened && t('menu.userMenu')}>
          <IconButton ref={anchorEl} color="inherit" onClick={() => setMenuOpened(true)}>
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <PopperMenu
          anchorEl={anchorEl.current}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 3 }}
          placement="bottom-end"
          open={menuOpened}
          onClose={() => setMenuOpened(false)}
        >
          <ListItem sx={{ maxWidth: 220 }}>
            <Avatar sx={{ mr: 1 }} />
            <ListItemText
              primary={user.email}
              secondary={t(userRoleMap[user.role])}
              primaryTypographyProps={{ noWrap: true, title: user.email }}
            />
          </ListItem>
          <Divider />
          <ListItemButton
            onClick={() => {
              navigate(ERoutes.ChangePassword);
              setMenuOpened(false);
            }}
            dense
          >
            <ListItemText primary={t('login.changePassword')} />
          </ListItemButton>
          <ListItemButton onClick={signOut} dense>
            <ListItemText primary={t('login.signOut')} />
          </ListItemButton>
        </PopperMenu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
