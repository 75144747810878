import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  LogicOperatorButton,
  PopperMenu,
  PopperSelect,
  type ILogicOperator,
  type TBaseInput,
} from 'components';
import ButtonInputSearch from './ButtonInputSearch';

export interface IMultiSelectButtonInputOption {
  name: string;
  id: string;
}

type Operator = 'equal' | 'notEqual';

export interface IMultiSelectButtonInputState {
  values: string[];
  operator: Operator;
}

interface IProps {
  options: IMultiSelectButtonInputOption[];
  loading?: boolean;
  onToggle?: (open: boolean) => void;
}

export const MultiSelectButtonInput = ({
  atom,
  options,
  label,
  loading,
  onToggle,
  disabled,
}: TBaseInput<IProps, IMultiSelectButtonInputState>) => {
  const { t } = useTranslation();
  const [state, setState] = useAtom(atom);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const logicOperators = [
    { sign: '=', label: t('inputButtons.equal'), key: 'equal' },
    { sign: '≠', label: t('inputButtons.notEqual'), key: 'notEqual' },
  ] as ILogicOperator<Operator>[];
  const buttonRef = useRef<HTMLButtonElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const sortedOptions = useMemo(() => {
    return [
      ...options.filter((el) => state.values.includes(el.id)),
      ...options.filter((el) => !state.values.includes(el.id)),
    ];
    // don't add state.values to dependency array because we want to sort options only on popover toggle
  }, [options, open]);

  const optionsToShow = useMemo(() => {
    const s = search.toLowerCase();
    return sortedOptions.filter((item) => item.name.toLowerCase().includes(s));
  }, [search, sortedOptions]);

  const showClearAll = useMemo(
    () => optionsToShow.length > 0 && optionsToShow.every((item) => state.values.includes(item.id)),
    [optionsToShow, state.values],
  );

  useEffect(() => {
    open && setTimeout(() => searchRef.current?.focus());
    onToggle?.(open);
    !open && setSearch('');
  }, [open, onToggle]);

  const toggleValue = useCallback((id: string) => {
    setState((prevState) => {
      const values = prevState.values.includes(id)
        ? prevState.values.filter((el) => el !== id)
        : [...prevState.values, id];
      return { ...prevState, values };
    });
  }, []);

  const handleSelectAll = useCallback(
    () =>
      setState((prevState) => {
        if (showClearAll) {
          return {
            ...prevState,
            values: prevState.values.filter((id) => !optionsToShow.some((item) => item.id === id)),
          };
        }
        return {
          ...prevState,
          values: [
            ...prevState.values,
            ...optionsToShow.map((item) => item.id).filter((id) => !prevState.values.includes(id)),
          ],
        };
      }),
    [optionsToShow, showClearAll],
  );

  const handleInvert = useCallback(
    () =>
      setState((prevState) => {
        const { itemsToRemove, itemsToAdd } = optionsToShow.reduce(
          (acc, item) => {
            if (prevState.values.includes(item.id)) {
              return { ...acc, itemsToRemove: [...acc.itemsToRemove, item.id] };
            } else {
              return { ...acc, itemsToAdd: [...acc.itemsToAdd, item.id] };
            }
          },
          { itemsToRemove: [], itemsToAdd: [] } as { itemsToRemove: string[]; itemsToAdd: string[] },
        );

        return {
          ...prevState,
          values: [...prevState.values.filter((id) => !itemsToRemove.includes(id)), ...itemsToAdd],
        };
      }),
    [optionsToShow],
  );

  return (
    <>
      <Button
        disabled={disabled}
        ref={buttonRef}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.values.length ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.values.length > 0 && (
          <>
            <LogicOperatorButton
              operators={logicOperators}
              value={state.operator}
              onChange={(operator) => setState((s) => ({ ...s, operator }))}
            />
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ width: 20, height: 20 }} />
              </Box>
            ) : (
              options.find((el) => el.id === state.values[0])?.name
            )}
            {state.values.length > 1 && (
              <Chip
                size="small"
                color="primary"
                label={'+' + (state.values.length - 1)}
                sx={{ height: 18, marginLeft: 0.5 }}
              />
            )}
          </>
        )}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>

      <PopperMenu
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        paperProps={{ sx: { p: 0.5 } }}
      >
        <Box>
          <PopperSelect
            sx={{ margin: 1, width: 250 }}
            value={state.operator}
            options={logicOperators.map((operator) => ({
              key: operator.key,
              label: `${label} ${operator.sign} (${operator.label})`,
            }))}
            onChange={(value) => setState((s) => ({ ...s, operator: value as Operator }))}
          />
        </Box>

        <ButtonInputSearch value={search} onValueChange={setSearch} ref={searchRef} />
        {loading ? (
          <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <List
              sx={{
                overflow: 'auto',
                maxHeight: 240,
                minHeight: 60,
              }}
            >
              {optionsToShow.map((option) => (
                <ListItem key={option.id} disablePadding>
                  <ListItemButton dense onClick={() => toggleValue(option.id)}>
                    <Checkbox checked={state.values.some((el) => el === option.id)} edge="start" size="small" />
                    <ListItemText primary={option.name} />
                  </ListItemButton>
                </ListItem>
              ))}

              {optionsToShow.length === 0 && (
                <ListItem>
                  <ListItemText primary={t('inputButtons.NoMatchesFound')} />
                </ListItem>
              )}
            </List>

            {optionsToShow.length > 0 && (
              <Box sx={{ display: 'flex', width: '100%', gap: 1, padding: 1 }}>
                <Button size="small" fullWidth variant="outlined" onClick={handleSelectAll}>
                  {showClearAll ? t('base.DeselectAll') : t('base.SelectAll')}
                </Button>
                <Button size="small" fullWidth variant="outlined" onClick={handleInvert}>
                  {t('base.Invert')}
                </Button>
              </Box>
            )}
          </>
        )}
      </PopperMenu>
    </>
  );
};

export default MultiSelectButtonInput;
