import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Box,
  FilterCard,
  GameHistoryViewer,
  HeaderText,
  SubWindow,
  Typography,
  type IButtonInputsFormAppliedFilter,
} from 'components';
import { type TFilter } from 'components/ReportExport/types';
import {
  useButtonInputsFormWithDataGridAndDefaultQuery,
  useDefaultFiltersData,
  usePerms,
  useUserPreferences,
} from 'hooks';
import { isSpecificError, parseButtonInputsFormToWhere } from 'utils';
import NotFound from 'views/NotFound';
import {
  GET_PLAYER,
  parsePlayerBrowserDetailsData,
  type IPlayerBrowserDetailsRawData,
} from './parsePlayerBrowserDetailsData';
import { PlayerBrowserDetailsFilters } from './PlayerBrowserDetailsFilters';
import PlayerBrowserDetailsTable from './PlayerBrowserDetailsTable';

const keysMap: Record<string, string> = {
  games: 'slotId',
  win: 'creditAmount',
  bet: 'debitAmount',
  betTime: 'createdAt',
  currencies: 'currency',
  countries: 'country',
  clients: 'clientId',
  operators: 'operatorId',
  clusters: 'clusterId',
};

export const PlayerBrowserDetails = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const perms = usePerms();
  const [selectedBetId, setSelectedBetId] = useState<string | number | null>(null);
  const playerDataRef = useRef<Record<string, string | undefined> | null>(null);
  const [playerNotFound, setPlayerNotFound] = useState(false);
  const { userPreferences } = useUserPreferences();

  const { setWhereFilters, page, setPage, setSort, rawData, sort, whereFilters } =
    useButtonInputsFormWithDataGridAndDefaultQuery<IPlayerBrowserDetailsRawData>({
      GQL: GET_PLAYER,
      loadingKey: 'fetchPlayerBrowserDetailsData',
      shouldShowError: (err) => {
        const isPlayerNotFoundError = isSpecificError(err, 'ERROR: invalid input syntax for type uuid');
        setPlayerNotFound(isPlayerNotFoundError);
        return !isPlayerNotFoundError;
      },
    });
  const { filtersData, loading } = useDefaultFiltersData('fetchPlayerBrowserDetailsFilters');

  const handleAcceptFilters = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const whereFilters = parseButtonInputsFormToWhere(filters, keysMap);

    setWhereFilters({
      playerId: params.id,
      ...whereFilters,
    });
  }, []);

  const { data, totalCount, summary } = useMemo(() => {
    if (rawData) {
      const parsedData = parsePlayerBrowserDetailsData(
        rawData,
        i18n.language,
        userPreferences.timezone,
        whereFilters?.baseCurrency,
      );
      if (parsedData.data[0]) {
        playerDataRef.current = parsedData.data[0];
      }
      return parsedData;
    }
    return { data: [], totalCount: -1, summary: {} };
  }, [rawData, i18n.language, userPreferences.timezone]);

  const historyViewerUrl = useMemo(
    () => data.find((item) => item.id === selectedBetId)?.historyViewerUrl || '/',
    [selectedBetId, data],
  );

  if (!rawData && playerNotFound) {
    return (
      <NotFound
        overrideTitle={t('playerBrowser.title')}
        overrideSubtitle={t('playerBrowser.playerNotFound', { id: params.id })}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <HeaderText>{t('playerBrowser.detailsTitle')}</HeaderText>

      <FilterCard>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mb: 2 }}>
          {(
            [
              { key: 'playerId', translationKey: 'playerBrowser.playerId' },
              { key: 'playerExternalId', translationKey: 'playerBrowser.playerExternalId' },
              { key: 'playerInternalId', translationKey: 'playerBrowser.playerInternalId' },
              perms.clusters && { key: 'clusterLabel', translationKey: 'playerBrowser.cluster' },
              perms.clients && { key: 'clientLabel', translationKey: 'playerBrowser.client' },
              perms.operators && { key: 'operatorLabel', translationKey: 'playerBrowser.operator' },
              { key: 'country', translationKey: 'playerBrowser.country' },
            ] as const
          ).map(
            (item) =>
              item && (
                <Typography key={item.key} variant="body2" component="div">
                  <b>{t(item.translationKey)}</b>
                  {`: ${playerDataRef.current ? playerDataRef.current[item.key] : '...'}`}
                </Typography>
              ),
          )}
        </Box>
        <PlayerBrowserDetailsFilters onAccept={handleAcceptFilters} filtersData={filtersData} loading={loading} />
      </FilterCard>

      <PlayerBrowserDetailsTable
        data={data}
        page={page}
        totalCount={totalCount}
        setPage={setPage}
        setSort={setSort}
        sort={sort}
        selectedBetId={selectedBetId}
        setSelectedBetId={setSelectedBetId}
        filter={whereFilters as TFilter}
        summary={summary}
      />
      <SubWindow
        onClose={() => setSelectedBetId(null)}
        startOpened
        title={t('playerBrowser.roundDetails')}
        sx={{ visibility: selectedBetId ? 'visible' : 'hidden' }}
        startSize={{ x: 700, y: window.innerHeight - 150 }}
        startPosition={{ x: Math.max(0, window.innerWidth - 740), y: 85 }}
        elevation={8}
      >
        <GameHistoryViewer betId={selectedBetId} elevation={8} url={historyViewerUrl} />
      </SubWindow>
    </Box>
  );
};

export default PlayerBrowserDetails;
