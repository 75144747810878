import { useCallback, useEffect, useState, type MutableRefObject } from 'react';
import { type GridDimensions } from '@mui/x-data-grid';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';

const sizes = {
  defaultHeight: 240,
  horizontalSpacing: 80,
  toolbarContainerHeight: 34.75,
  paginationHeight: 52,
};

type TableHeightProps = {
  apiRef: MutableRefObject<GridApiCommunity>;
  additionalRowCount?: number;
};

const computeFinalTableHeight = (
  apiRef: MutableRefObject<GridApiCommunity>,
  tableHeightConstraint: number,
  additionalRowCount?: number,
) => {
  const rootDimensions = apiRef.current.getRootDimensions();
  const totalRows = apiRef.current.getRowsCount();
  const tableEstimatedHeight = computeEstimatedTableHeight(rootDimensions, totalRows, additionalRowCount);

  if (totalRows === 0) {
    return sizes.defaultHeight;
  }

  if (tableEstimatedHeight > tableHeightConstraint) {
    return tableHeightConstraint;
  }

  return tableEstimatedHeight;
};

const computeEstimatedTableHeight = (
  gridDimensions: GridDimensions,
  totalRows: number,
  additionalRowCount: number = 0,
) => {
  const { rowHeight, headerHeight } = gridDimensions;
  const { paginationHeight, toolbarContainerHeight } = sizes;

  const rowsHeight = totalRows * rowHeight;

  return toolbarContainerHeight + headerHeight + rowsHeight + additionalRowCount * rowHeight + paginationHeight;
};

const computeCaseBodyScrollable = (apiRef: MutableRefObject<GridApiCommunity>, additionalRowCount?: number) => {
  const tableHeightConstraint = window.innerHeight - sizes.horizontalSpacing * 2;

  return computeFinalTableHeight(apiRef, tableHeightConstraint, additionalRowCount);
};

const computeCaseToFitTable = (apiRef: MutableRefObject<GridApiCommunity>, additionalRowCount?: number) => {
  const rootElement = apiRef.current.rootElementRef.current;

  if (!rootElement) {
    return 0;
  }

  // dont take scroll into account because it's assumed the view isn't scrollable (table + filters ~= 100vh)
  const offsetTop = rootElement.getBoundingClientRect().y;
  const tableHeightConstraint = window.innerHeight - offsetTop - sizes.horizontalSpacing;

  return computeFinalTableHeight(apiRef, tableHeightConstraint, additionalRowCount);
};

const shouldTableFillRemainingHeight = (rootElement: HTMLDivElement) => {
  const absoluteOffsetTop = rootElement.getBoundingClientRect().y + window.scrollY;

  return absoluteOffsetTop < window.innerHeight / 2;
};

const calculateHeight = (apiRef: MutableRefObject<GridApiCommunity>, additionalRowCount?: number) => {
  const rootElement = apiRef.current.rootElementRef.current;

  if (!rootElement) {
    return 0;
  }

  if (shouldTableFillRemainingHeight(rootElement)) {
    // assume table only has to fill remaining height space (also assume page isn't scrollable)
    return computeCaseToFitTable(apiRef, additionalRowCount);
  } else {
    // table can take whole view height
    return computeCaseBodyScrollable(apiRef, additionalRowCount);
  }
};

export const useComputedGridHeight = ({ apiRef, additionalRowCount }: TableHeightProps) => {
  const [height, setHeight] = useState(sizes.defaultHeight);

  const updateHeightState = useCallback(() => {
    if (!apiRef.current) {
      return;
    }
    const computedHeight = calculateHeight(apiRef, additionalRowCount);
    setHeight(computedHeight);
  }, [apiRef, additionalRowCount]);

  useEffect(() => {
    if (!apiRef.current) {
      return;
    }

    const gridApi = apiRef.current;

    const unsubscribePaginationChange = gridApi.subscribeEvent('rowCountChange', updateHeightState);
    const unsubscribeResize = gridApi.subscribeEvent('resize', updateHeightState);

    return () => {
      unsubscribePaginationChange();
      unsubscribeResize();
    };
  }, [apiRef, additionalRowCount]);

  return {
    height,
  };
};
